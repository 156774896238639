import React from "react";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import InfoIcon from "@mui/icons-material/Info";

import AboutSection from "./AboutSection";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import post1 from "./blog-post.1.md";
import post2 from "./blog-post.2.md";
import post3 from "./blog-post.3.md";
import "font-awesome/css/font-awesome.css";
import { TransitionLink } from "react-wipes";
import { Overlay as SplitDiagnolWipe } from "react-wipes/SplitDiagonalWipe";
import { Overlay as DiagonalWipe } from "react-wipes/DiagonalWipe";

const useStyles = makeStyles((theme) => ({
  mainGrid: {
    marginTop: theme.spacing(3),
  },
}));

const mainFeaturedPost = {
  title: "Title of a longer featured blog post",
  description:
    "Multiple lines of text that form the lede, informing new readers quickly and efficiently about what's most interesting in this post's contents.",
  image: "https://source.unsplash.com/random",
  imgText: "main image description",
  linkText: "Continue reading…",
};

const featuredPosts = [
  {
    title: "Featured post",
    date: "Nov 12",
    description:
      "This is a wider card with supporting text below as a natural lead-in to additional content.",
    image: "https://source.unsplash.com/random",
    imageText: "Image Text",
  },
  {
    title: "Post title",
    date: "Nov 11",
    description:
      "This is a wider card with supporting text below as a natural lead-in to additional content.",
    image: "https://source.unsplash.com/random",
    imageText: "Image Text",
  },
];

const posts = [post1, post2, post3];

const sidebar = {
  title: "About",
  crypto: [
    {
      name: "0xba2de839597950d1f77a9a32a3e79d21ad739bb5",
      icon: <img src={require("./Ethereum-Logo.svg").default} width={60} />,
      href: "https://github.com/rhigdon",
    },
    // { name: 'Twitter', icon: TwitterIcon },
    // { name: 'Facebook', icon: FacebookIcon },
  ],
  description:
    "Ryan is a software engineer with over a decade of experience." +
    "  He primarly uses python, golang, and react when developing.",
  sites: [
    {
      name: "My Blog",
      icon: <img src={require("./favicon.ico").default} width={25} />,
      href: "https://blog.ryanhigdon.com/",
    },
    {
      name: "Coworker Lab",
      icon: <img src={require("./colab.ico").default} width={25} />,
      href: "https://co-lab.team/",
    },
    {
      name: "Foodie Hero",
      icon: <img src={require("./pizza.svg").default} width={25} />,
      href: "http://foodiehero.us/",
    },
    {
      name: "React Wipes",
      icon: <img src={require("./cinema_icon.svg").default} width={25} />,
      href: "https://rhigdon.github.io/react-wipes/",
    },
    {
      name: "Crypto-Graph",
      icon: (
        <img
          src={require("./crypto-graph-favicon-green.ico").default}
          width={25}
        />
      ),
      href: "https://crypto-graph.net",
    },
  ],
  social: [
    {
      name: "GitHub",
      icon: <GitHubIcon sx={{ color: "black" }} />,
      href: "https://github.com/rhigdon",
    },
    {
      name: "LinkedIn",
      icon: <LinkedInIcon sx={{ color: "#0077b5" }} />,
      href: "https://www.linkedin.com/in/ryan-higdon-6966464/",
    },
    {
      name: "Instagram",
      icon: <InstagramIcon sx={{ color: "purple" }} />,
      href: "https://www.instagram.com/natureshots0404/",
    },
    // { name: 'Twitter', icon: TwitterIcon },
    // { name: 'Facebook', icon: FacebookIcon },
    {
      name: "StackOverflow",
      icon: <Icon className="fa fa-stack-overflow" sx={{ color: "orange" }} />,
      href: "https://stackoverflow.com/users/77331/rhigdon?tab=profile",
    },
    {
      name: "Discord",
      icon: (
        <SportsEsportsIcon
          sx={{ color: "#5865F2", top: 5, position: "relative" }}
        />
      ),
      href: "https://discord.gg/GqfrBqGGYN",
    },
  ],
};

export default function Blog() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Container disableGutters={true}>
        <main>
          {/*
            <MainFeaturedPost post={mainFeaturedPost} />
            <Grid container spacing={4}>
              {featuredPosts.map(post => (
                <FeaturedPost key={post.title} post={post} />
              ))}
            </Grid>
          */}
          <Grid container spacing={5} className={classes.mainGrid}>
            {/*
              <Main title="From the firehose" posts={posts} />
            */}
            <AboutSection
              title={sidebar.title}
              description={sidebar.description}
              archives={sidebar.archives}
              social={sidebar.social}
            />
            <Grid item>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography
                  variant="h6"
                  color="secondary"
                  className={classes.sidebarSection}
                >
                  Social
                </Typography>
                <Tooltip
                  arrow
                  title="Here are some places you can find me on the internet!"
                >
                  <InfoIcon fontSize="sm" />
                </Tooltip>
              </Stack>
              <Divider sx={{ marginBottom: 1 }} />
              <Stack spacing={1}>
                {sidebar.social.map((network) => (
                  <TransitionLink
                    color="grey"
                    to={network.href}
                    key={network.name}
                    overlay={SplitDiagnolWipe}
                  >
                    <Link>
                      <Grid
                        container
                        direction="row"
                        spacing={1}
                        alignItems="center"
                      >
                        <Grid item>{network.icon}</Grid>
                        <Grid item>{network.name}</Grid>
                      </Grid>
                    </Link>
                  </TransitionLink>
                ))}
              </Stack>
            </Grid>
            <Grid item>
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography
                  variant="h6"
                  color="secondary"
                  className={classes.sidebarSection}
                >
                  Portfolio Sites
                </Typography>
                <Tooltip arrow title="Here are a few sites I've developed">
                  <InfoIcon fontSize="sm" />
                </Tooltip>
              </Stack>
              <Divider sx={{ marginBottom: 1 }} />
              <Stack spacing={1}>
                {sidebar.sites.map((network) => (
                  <TransitionLink
                    color="grey"
                    to={network.href}
                    key={network.name}
                    overlay={DiagonalWipe}
                  >
                    <Link display="block" variant="body1">
                      <Grid
                        container
                        direction="row"
                        spacing={1}
                        alignItems="center"
                      >
                        <Grid item>{network.icon}</Grid>
                        <Grid item>{network.name}</Grid>
                      </Grid>
                    </Link>
                  </TransitionLink>
                ))}
              </Stack>
            </Grid>
          </Grid>
        </main>
      </Container>
    </React.Fragment>
  );
}
